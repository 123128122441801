"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchivedDaysheet_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var BulkInsurancePayment_1 = require("./BulkInsurancePayment");
var ControlNumber_1 = require("./ControlNumber");
var Location_1 = require("./Location");
var Transaction_1 = require("./Transaction");
var User_1 = require("./User");
var ArchivedDaysheet_Factory = /** @class */ (function () {
    function ArchivedDaysheet_Factory() {
    }
    ArchivedDaysheet_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentsCount: model.AdjustmentsCount,
            AdjustmentsTotal: model.AdjustmentsTotal,
            BulkInsurancePayments: model.BulkInsurancePayments && model.BulkInsurancePayments.map(function (i) { return BulkInsurancePayment_1.BulkInsurancePayment_Factory.CreateIncoming(i); }),
            ChargesCount: model.ChargesCount,
            ChargesTotal: model.ChargesTotal,
            ControlNumber: ControlNumber_1.ControlNumber_Factory.CreateIncoming(model.ControlNumber),
            ControlNumberId: model.ControlNumberId,
            CurrentAR: model.CurrentAR,
            DateClosed: Date_Factory_1.Date_Factory.CreateIncoming(model.DateClosed),
            DepositsCount: model.DepositsCount,
            DepositsTotal: model.DepositsTotal,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            LocationId: model.LocationId,
            PaymentsCount: model.PaymentsCount,
            PaymentsTotal: model.PaymentsTotal,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateIncoming(i); }),
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    ArchivedDaysheet_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdjustmentsCount: model.AdjustmentsCount,
            AdjustmentsTotal: model.AdjustmentsTotal,
            BulkInsurancePayments: model.BulkInsurancePayments && model.BulkInsurancePayments.map(function (i) { return BulkInsurancePayment_1.BulkInsurancePayment_Factory.CreateOutgoing(i); }),
            ChargesCount: model.ChargesCount,
            ChargesTotal: model.ChargesTotal,
            ControlNumber: ControlNumber_1.ControlNumber_Factory.CreateOutgoing(model.ControlNumber),
            ControlNumberId: model.ControlNumberId,
            CurrentAR: model.CurrentAR,
            DateClosed: Date_Factory_1.Date_Factory.CreateOutgoing(model.DateClosed),
            DepositsCount: model.DepositsCount,
            DepositsTotal: model.DepositsTotal,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            LocationId: model.LocationId,
            PaymentsCount: model.PaymentsCount,
            PaymentsTotal: model.PaymentsTotal,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            Transactions: model.Transactions && model.Transactions.map(function (i) { return Transaction_1.Transaction_Factory.CreateOutgoing(i); }),
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return ArchivedDaysheet_Factory;
}());
exports.ArchivedDaysheet_Factory = ArchivedDaysheet_Factory;
