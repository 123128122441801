"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentType_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Procedure_1 = require("./Procedure");
var AppointmentType_Factory = /** @class */ (function () {
    function AppointmentType_Factory() {
    }
    AppointmentType_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Color: model.Color,
            DefaultClinicalUI: model.DefaultClinicalUI,
            Description: model.Description,
            Duration: model.Duration,
            IsRecall: model.IsRecall,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            Name: model.Name,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateIncoming(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequiresFollowUp: model.RequiresFollowUp,
            ShortCode: model.ShortCode,
        };
        return result;
    };
    AppointmentType_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Color: model.Color,
            DefaultClinicalUI: model.DefaultClinicalUI,
            Description: model.Description,
            Duration: model.Duration,
            IsRecall: model.IsRecall,
            LegacyKey: model.LegacyKey,
            LegacyKeyHash: model.LegacyKeyHash,
            Name: model.Name,
            Procedures: model.Procedures && model.Procedures.map(function (i) { return Procedure_1.Procedure_Factory.CreateOutgoing(i); }),
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            RequiresFollowUp: model.RequiresFollowUp,
            ShortCode: model.ShortCode,
        };
        return result;
    };
    return AppointmentType_Factory;
}());
exports.AppointmentType_Factory = AppointmentType_Factory;
