"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteVersion_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Location_1 = require("./Location");
var User_1 = require("./User");
var NoteVersion_Factory = /** @class */ (function () {
    function NoteVersion_Factory() {
    }
    NoteVersion_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseLocationId: model.EnterpriseLocationId,
            IsClinicallyRelevant: model.IsClinicallyRelevant,
            Location: Location_1.Location_Factory.CreateIncoming(model.Location),
            NoteContent: model.NoteContent,
            NoteContentTypeCode: model.NoteContentTypeCode,
            NoteId: model.NoteId,
            NoteVersion1: model.NoteVersion1,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordedAtLocation: model.RecordedAtLocation,
            RecordedByUser: model.RecordedByUser,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
        };
        return result;
    };
    NoteVersion_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            EnterpriseLocationId: model.EnterpriseLocationId,
            IsClinicallyRelevant: model.IsClinicallyRelevant,
            Location: Location_1.Location_Factory.CreateOutgoing(model.Location),
            NoteContent: model.NoteContent,
            NoteContentTypeCode: model.NoteContentTypeCode,
            NoteId: model.NoteId,
            NoteVersion1: model.NoteVersion1,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordedAtLocation: model.RecordedAtLocation,
            RecordedByUser: model.RecordedByUser,
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
        };
        return result;
    };
    return NoteVersion_Factory;
}());
exports.NoteVersion_Factory = NoteVersion_Factory;
