"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Procedure_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var CDTCode_1 = require("./CDTCode");
var ClinicalTreatmentMaterial_1 = require("./ClinicalTreatmentMaterial");
var CPTCode_1 = require("./CPTCode");
var NHSCategory_1 = require("./NHSCategory");
var NHSCatDetails_1 = require("./NHSCatDetails");
var ProcedureGrouping_1 = require("./ProcedureGrouping");
var ProcedureList_1 = require("./ProcedureList");
var ToothSurfaceNumberType_1 = require("./ToothSurfaceNumberType");
var TreatmentSubType_1 = require("./TreatmentSubType");
var Procedure_Factory = /** @class */ (function () {
    function Procedure_Factory() {
    }
    Procedure_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            CDTCode: CDTCode_1.CDTCode_Factory.CreateIncoming(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ClinicalTreatmentMaterial: ClinicalTreatmentMaterial_1.ClinicalTreatmentMaterial_Factory.CreateIncoming(model.ClinicalTreatmentMaterial),
            ClinicalTreatmentMaterialCode: model.ClinicalTreatmentMaterialCode,
            CPTCode: CPTCode_1.CPTCode_Factory.CreateIncoming(model.CPTCode),
            CPTCodeId: model.CPTCodeId,
            CreatedByUserId: model.CreatedByUserId,
            EnableAlternateBilling: model.EnableAlternateBilling,
            Fee: model.Fee,
            IsActiveProcedure: model.IsActiveProcedure,
            IsDistal: model.IsDistal,
            IsFacialBuccal: model.IsFacialBuccal,
            IsGroupedProcedure: model.IsGroupedProcedure,
            IsHygiene: model.IsHygiene,
            IsLingualPalatal: model.IsLingualPalatal,
            IsMesial: model.IsMesial,
            IsOcclusalIncisal: model.IsOcclusalIncisal,
            Minutes: model.Minutes,
            NHSCategory: NHSCategory_1.NHSCategory_Factory.CreateIncoming(model.NHSCategory),
            NHSCategoryBandDescription: model.NHSCategoryBandDescription,
            NHSCategoryDescription: model.NHSCategoryDescription,
            NHSCategoryDetails: NHSCatDetails_1.NHSCatDetails_Factory.CreateIncoming(model.NHSCategoryDetails),
            NHSCatID: model.NHSCatID,
            NHSCatOverriden: model.NHSCatOverriden,
            NHSFilterMask: model.NHSFilterMask,
            NumberSurfacesTypeCode: model.NumberSurfacesTypeCode,
            OfficeCode: model.OfficeCode,
            OfficeDescription: model.OfficeDescription,
            Phase: model.Phase,
            PreviousVersionId: model.PreviousVersionId,
            ProcedureGroupings: model.ProcedureGroupings && model.ProcedureGroupings.map(function (i) { return ProcedureGrouping_1.ProcedureGrouping_Factory.CreateIncoming(i); }),
            ProcedureGroupings1: model.ProcedureGroupings1 && model.ProcedureGroupings1.map(function (i) { return ProcedureGrouping_1.ProcedureGrouping_Factory.CreateIncoming(i); }),
            ProcedureList: ProcedureList_1.ProcedureList_Factory.CreateIncoming(model.ProcedureList),
            ProcedureListId: model.ProcedureListId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ResetsRecall: model.ResetsRecall,
            SNOMEDCode: model.SNOMEDCode,
            ToothSurfaceNumberType: ToothSurfaceNumberType_1.ToothSurfaceNumberType_Factory.CreateIncoming(model.ToothSurfaceNumberType),
            TreatmentSubType: TreatmentSubType_1.TreatmentSubType_Factory.CreateIncoming(model.TreatmentSubType),
            TreatmentSubTypeId: model.TreatmentSubTypeId,
        };
        return result;
    };
    Procedure_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            CDTCode: CDTCode_1.CDTCode_Factory.CreateOutgoing(model.CDTCode),
            CDTCodeId: model.CDTCodeId,
            ClinicalTreatmentMaterial: ClinicalTreatmentMaterial_1.ClinicalTreatmentMaterial_Factory.CreateOutgoing(model.ClinicalTreatmentMaterial),
            ClinicalTreatmentMaterialCode: model.ClinicalTreatmentMaterialCode,
            CPTCode: CPTCode_1.CPTCode_Factory.CreateOutgoing(model.CPTCode),
            CPTCodeId: model.CPTCodeId,
            CreatedByUserId: model.CreatedByUserId,
            EnableAlternateBilling: model.EnableAlternateBilling,
            Fee: model.Fee,
            IsActiveProcedure: model.IsActiveProcedure,
            IsDistal: model.IsDistal,
            IsFacialBuccal: model.IsFacialBuccal,
            IsGroupedProcedure: model.IsGroupedProcedure,
            IsHygiene: model.IsHygiene,
            IsLingualPalatal: model.IsLingualPalatal,
            IsMesial: model.IsMesial,
            IsOcclusalIncisal: model.IsOcclusalIncisal,
            Minutes: model.Minutes,
            NHSCategory: NHSCategory_1.NHSCategory_Factory.CreateOutgoing(model.NHSCategory),
            NHSCategoryBandDescription: model.NHSCategoryBandDescription,
            NHSCategoryDescription: model.NHSCategoryDescription,
            NHSCategoryDetails: NHSCatDetails_1.NHSCatDetails_Factory.CreateOutgoing(model.NHSCategoryDetails),
            NHSCatID: model.NHSCatID,
            NHSCatOverriden: model.NHSCatOverriden,
            NHSFilterMask: model.NHSFilterMask,
            NumberSurfacesTypeCode: model.NumberSurfacesTypeCode,
            OfficeCode: model.OfficeCode,
            OfficeDescription: model.OfficeDescription,
            Phase: model.Phase,
            PreviousVersionId: model.PreviousVersionId,
            ProcedureGroupings: model.ProcedureGroupings && model.ProcedureGroupings.map(function (i) { return ProcedureGrouping_1.ProcedureGrouping_Factory.CreateOutgoing(i); }),
            ProcedureGroupings1: model.ProcedureGroupings1 && model.ProcedureGroupings1.map(function (i) { return ProcedureGrouping_1.ProcedureGrouping_Factory.CreateOutgoing(i); }),
            ProcedureList: ProcedureList_1.ProcedureList_Factory.CreateOutgoing(model.ProcedureList),
            ProcedureListId: model.ProcedureListId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ResetsRecall: model.ResetsRecall,
            SNOMEDCode: model.SNOMEDCode,
            ToothSurfaceNumberType: ToothSurfaceNumberType_1.ToothSurfaceNumberType_Factory.CreateOutgoing(model.ToothSurfaceNumberType),
            TreatmentSubType: TreatmentSubType_1.TreatmentSubType_Factory.CreateOutgoing(model.TreatmentSubType),
            TreatmentSubTypeId: model.TreatmentSubTypeId,
        };
        return result;
    };
    return Procedure_Factory;
}());
exports.Procedure_Factory = Procedure_Factory;
