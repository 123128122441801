import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AppConfigProvider } from "../shared/useAppConfig";
import { publicRoutes } from "../../routePaths";
import LazyLoad from "../shared/LazyLoad";
import { PatientModeProvider } from "../PatientMode/usePatientMode";
import AppErrorBoundary from "./AppErrorBoundary";

const publicFormsLoader = () => import(/* webpackChunkName: "lzPublicForms" */ "../publicForms/PublicFormsBaseRoute");
const LazyPublicForms: React.FC= () => <LazyLoad componentLoader={publicFormsLoader} />;

const loginLoader = () => import(/* webpackChunkName: "lzLogin" */ "../auth/LoginContainer");
const LazyLogin: React.FC = () => <LazyLoad componentLoader={loginLoader} />;

const UserSessionLoader = () => import(/* webpackChunkName: "lzUserSession" */ "./UserSession");
const LazyUserSession: React.FC = () => <LazyLoad componentLoader={UserSessionLoader} />;


const App: React.FC = () => {
  return (
    <div className="app">
      <AppErrorBoundary>
        <AppConfigProvider>
          <PatientModeProvider>
            <BrowserRouter>
              <Switch>
                <Route path={publicRoutes.forms.base} component={LazyPublicForms} />
                <Route path={publicRoutes.login.base} component={LazyLogin} />
                <Route component={LazyUserSession} />
              </Switch>
            </BrowserRouter>
          </PatientModeProvider>
        </AppConfigProvider>
      </AppErrorBoundary>
    </div>
  );
}


export default App;
