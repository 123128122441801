"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermission_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var UserPermission_Factory = /** @class */ (function () {
    function UserPermission_Factory() {
    }
    UserPermission_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdminPermissionSet: model.AdminPermissionSet,
            ClinicalPermissionSet: model.ClinicalPermissionSet,
            FinancialPermissionSet: model.FinancialPermissionSet,
            GeneralPermissionSet: model.GeneralPermissionSet,
            InsurancePermissionSet: model.InsurancePermissionSet,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReportsPermissionSet: model.ReportsPermissionSet,
            SpecialPermissionSet: model.SpecialPermissionSet,
        };
        return result;
    };
    UserPermission_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdminPermissionSet: model.AdminPermissionSet,
            ClinicalPermissionSet: model.ClinicalPermissionSet,
            FinancialPermissionSet: model.FinancialPermissionSet,
            GeneralPermissionSet: model.GeneralPermissionSet,
            InsurancePermissionSet: model.InsurancePermissionSet,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReportsPermissionSet: model.ReportsPermissionSet,
            SpecialPermissionSet: model.SpecialPermissionSet,
        };
        return result;
    };
    return UserPermission_Factory;
}());
exports.UserPermission_Factory = UserPermission_Factory;
