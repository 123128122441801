import React, { useMemo } from "react";
import { Spinner as BpSpinner, Classes, Intent } from "@blueprintjs/core";
import classnames from "classnames";

enum IconSize {
  Small = BpSpinner.SIZE_SMALL,
  Medium = BpSpinner.SIZE_STANDARD,
  Large = BpSpinner.SIZE_LARGE
};


const Text = ({ text }: { text: string }) =>
  <div className={classnames(Classes.TEXT_MUTED, Classes.TEXT_SMALL)} style={{ textAlign: 'center', padding: '4px 8px' }}>{text}</div>;

export interface IWaitProps {
  useVerticalLayout?: boolean;
  leftOtTopText?: string;
  rightOrBottomText?: string;
  size?: IconSize;
  showBorder?: boolean;
  style?: React.CSSProperties;
};


const Wait: React.FC<IWaitProps> & {IconSize: typeof IconSize} = ({
  leftOtTopText,
  rightOrBottomText = 'Loading...',
  size = IconSize.Small,
  style,
  useVerticalLayout,
  showBorder }) => {

  const innerStyle = useMemo<React.CSSProperties>(() => ({
    height: 'unset',
    width: 'unset',
    borderRadius: 4,
    border: showBorder !== false ? 'solid 1px #cde' : undefined,
    boxShadow: showBorder !== false ? '0 0 3px #cde, 1px 1px 2px #cde' : undefined,
    flexDirection: useVerticalLayout ? 'column' : 'row',
    background: '#fff',
    ...style
  }), [showBorder, style, useVerticalLayout]);

  return (
      <div className="centered" style={innerStyle} >
        {leftOtTopText && <Text text={leftOtTopText} />}
        <BpSpinner intent={Intent.PRIMARY} size={size} />
        {rightOrBottomText && <Text text={rightOrBottomText} />}
      </div>
  );
}


Wait.IconSize = IconSize;
export default Wait;

