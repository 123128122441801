"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PatientNotification_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var Patient_1 = require("./Patient");
var User_1 = require("./User");
var PatientNotification_Factory = /** @class */ (function () {
    function PatientNotification_Factory() {
    }
    PatientNotification_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Expires: model.Expires,
            ExpiryDate: Date_Factory_1.Date_Factory.CreateIncoming(model.ExpiryDate),
            IsVisible: model.IsVisible,
            NotificationTypeCode: model.NotificationTypeCode,
            Patient: Patient_1.Patient_Factory.CreateIncoming(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateIncoming(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    PatientNotification_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            Description: model.Description,
            Expires: model.Expires,
            ExpiryDate: Date_Factory_1.Date_Factory.CreateOutgoing(model.ExpiryDate),
            IsVisible: model.IsVisible,
            NotificationTypeCode: model.NotificationTypeCode,
            Patient: Patient_1.Patient_Factory.CreateOutgoing(model.Patient),
            PatientId: model.PatientId,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            User: User_1.User_Factory.CreateOutgoing(model.User),
            UserId: model.UserId,
        };
        return result;
    };
    return PatientNotification_Factory;
}());
exports.PatientNotification_Factory = PatientNotification_Factory;
