
export const publicRoutes = {
  login: {
    base: "/pub/login",
    signIn: "/pub/login/signin",
    sessionExpired: "/pub/login/sessionExpired",
    tenant: "/pub/login/tenant"
  },

  forms: {
    base: "/pub/:tiid/forms/",
    view: "/pub/:tiid/forms/:templateId/locations/:locationId/",
    formCompletedPathSuffix: "/done"
  }
};


export const routes = {
  home: { path: "/" },

  formLib: { path: "/forms/lib" },

  formAdminLib: { path: "/forms/lib/:showAdmin" },

  formQueue: { path: "/forms/queue" },

  patientSearch: { path: "/patients" },
  patient: { path: "/patients/:patientId?" },

  patientMode: {
    root: { path: "/patient-mode" },
    auth: { path: "/patient-mode/auth" },
    forms: { path: "/patient-mode/forms" },
    formInstance: { path: "/patient-mode/forms/:instanceId" },
  },

  appointments: { path: "/appointments" },

  formTemplate: { path: "/forms/template/:templateId" },
  //formInstance: { path: "/forms/instance/:instanceId" },
  formPrint: { path: "/forms/print" },


  admin: {
    root: { path: "/admin" },
    settings: { path: "/admin/settings" },
    users: { path: "/admin/users" },
    locations: { path: "/admin/locations" },
    forms: { path: "/admin/forms" }
  }

}
