"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CorrespondenceMergeField_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var DataExportTemplateMergeField_1 = require("./DataExportTemplateMergeField");
var CorrespondenceMergeField_Factory = /** @class */ (function () {
    function CorrespondenceMergeField_Factory() {
    }
    CorrespondenceMergeField_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            DataExportTemplateMergeFields: model.DataExportTemplateMergeFields && model.DataExportTemplateMergeFields.map(function (i) { return DataExportTemplateMergeField_1.DataExportTemplateMergeField_Factory.CreateIncoming(i); }),
            DisplayName: model.DisplayName,
            GroupId: model.GroupId,
            Id: model.Id,
            Name: model.Name,
        };
        return result;
    };
    CorrespondenceMergeField_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            DataExportTemplateMergeFields: model.DataExportTemplateMergeFields && model.DataExportTemplateMergeFields.map(function (i) { return DataExportTemplateMergeField_1.DataExportTemplateMergeField_Factory.CreateOutgoing(i); }),
            DisplayName: model.DisplayName,
            GroupId: model.GroupId,
            Id: model.Id,
            Name: model.Name,
        };
        return result;
    };
    return CorrespondenceMergeField_Factory;
}());
exports.CorrespondenceMergeField_Factory = CorrespondenceMergeField_Factory;
