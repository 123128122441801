import { getIn, setIn } from 'formik';
import { IEFormsInstance, EFormsInstance_Factory } from "csd.phoenix.models/EFormsInstance";
import { EFormsSourceTypes } from "csd.phoenix.models/EFormsSourceTypes";
import { EFormsStatusCodes } from "csd.phoenix.models/EFormsStatusCodes";
import { IEFormsInstanceView, EFormsInstanceView_Factory } from "csd.phoenix.models/EFormsInstanceView";
import { IEFormsStatusTracking } from "csd.phoenix.models/EFormsStatusTracking";
import { IPerson } from "csd.phoenix.models/Person";
import { PatientConnectionTypeCodes } from "csd.phoenix.models/PatientConnectionTypeCodes";
import { IEFormsInstanceUpdateResponse } from "csd.phoenix.models/EFormsInstanceUpdateResponse";
import { Date_Factory } from "csd.phoenix.models/Date_Factory";
import { loadConfig } from "../../services/appConfigService";
import http from "../../services/api/http";
import { IPagedApiResponse } from "../../models/PagedApiResponse";
import ODataApi from "../../services/api/odata";
import { ODataQueryParams, IApiQueryOptions } from "../../services/api/odata/models";
import { IColumnSort, getODataSortExpression } from "../../components/shared/DataTable/models";
import { EMPTY_GUID } from "../../utils/objectUtils";
import { getTemplateFieldBindings } from '../../components/shared/form/dynamicForm/utils';
import { standardizePhoneNumber } from '../../utils/dataFormatters';
import { IPrefillData } from "./prefillData";
import { getPrivateTemplateDetails, getPublicTemplateDetails } from './templates';
import { FormPartType } from "../../components/shared/form/models/baseTypes";



// export async function getInstances(statusCodes: EFormsStatusCodes[], pageNum: number, pageSize: number, includeInactive = false, patientId?: string) {
//   //console.log("Loading instance data for: " + [...statusCodes.values()].map(s => EFormsStatusCodes[s]).join(", "))

//   if (statusCodes.length === 0) {
//     return Promise.resolve([]);
//   }
//   const { apiUrl } = await loadConfig();
//   const url = apiUrl.segment(`eforms/instances`)
//   .param({
//     statusCds: statusCodes,
//     pageNum,
//     pageSize,
//     patientId,
//     includeInactive
//     })
//   .toString();

//   const { data: { Data }  } = await http.get<IPagedApiResponse<IEFormsInstance>>(url);
//   const instances = Data
//     .map(i => EFormsInstance_Factory.CreateIncoming(i)!)
//     .sort((i1, i2) => (i1.PatientName|| '').localeCompare(i2.PatientName));
//   return instances;
// }


export function getPatientNameFromJson(instanceJson?: object | string) {
  if (typeof instanceJson === "string") {
    instanceJson = JSON.parse(instanceJson);
  }

  if (instanceJson) {
    console.assert(typeof instanceJson === "object");
    const data = instanceJson as {[key: string]: any };
    if (data.Patient &&  data.Patient.Person) {
      return `${data.Patient.Person.FirstName} ${data.Patient.Person.LastName}`;
    }
  }

  return undefined;
}


export type JsonEntity = {
  id?: string;
  name: string;
}

export type JsonPersonEntity = {
  id?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  dob?: Date;
}

export interface IInstanceJsonEntities {
  patient?: JsonPersonEntity;
  responsibleParty?: JsonPersonEntity;
  primaryPayer?: JsonEntity;
  primaryPlan?: JsonEntity;
  secondaryPayer?: JsonEntity;
  secondaryPlan?: JsonEntity;
}


export function getInstanceJsonFields(instanceJson?: IPrefillData | string) {
  const entities: IInstanceJsonEntities = {}
  const json: IPrefillData = typeof instanceJson === "string" ? JSON.parse(instanceJson): instanceJson;
  const patient = json && json.Patient;
  console.assert(patient && patient.Person);

  if (!patient) {
    return entities;
  }

  const defaultPerson = {} as IPerson;
  const { Person = defaultPerson, ResponsibleParty, PrimaryInsurance, SecondaryInsurance } = patient;

  entities.patient = {
    id: patient.RecordId,
    firstName: Person.FirstName,
    middleName: Person.MiddleName,
    lastName: Person.LastName,
    title: Person.Title,
    dob: Date_Factory.CreateIncoming(Person.Birthdate as any, {ignoreTimePortion: true})
  }

  // Either id or name must exist
  if (ResponsibleParty && (ResponsibleParty.RecordId || ResponsibleParty.FirstName || ResponsibleParty.LastName)) {
    const isSelf = ResponsibleParty.RelationshipCode === PatientConnectionTypeCodes.Self;
    if (!isSelf) {
      entities.responsibleParty = {
        id: ResponsibleParty.RecordId,
        firstName: ResponsibleParty.FirstName,
        middleName: ResponsibleParty.MiddleName,
        lastName: ResponsibleParty.LastName,
        title: ResponsibleParty.Title,
        dob: Date_Factory.CreateIncoming(ResponsibleParty.Birthdate as any, {ignoreTimePortion: true})
      }
    }
  }

  // Either id or name must exist
  if (PrimaryInsurance && (PrimaryInsurance.PayerId || PrimaryInsurance.PayerName || PrimaryInsurance.PlanName)) {
    entities.primaryPayer = {
      id: PrimaryInsurance.PayerId,
      name: PrimaryInsurance.PayerName
    };

    entities.primaryPlan = {
      id: PrimaryInsurance.PlanId,
      name: PrimaryInsurance.PlanName
    }
  }

  // Either id or name must exist
  if (SecondaryInsurance && (SecondaryInsurance.PayerId || SecondaryInsurance.PayerName || SecondaryInsurance.PlanName)) {
    entities.secondaryPayer = {
      id: SecondaryInsurance.PayerId,
      name: SecondaryInsurance.PayerName
    };

    entities.secondaryPlan = {
      id: SecondaryInsurance.PlanId,
      name: SecondaryInsurance.PlanName
    }
  }

  return entities;
}


export async function getInstanceById(instanceId: string, throwIfNotFound: true): Promise<IEFormsInstanceView>;
export async function getInstanceById(instanceId: string, throwIfNotFound: false) : Promise<IEFormsInstanceView | undefined>;
export async function getInstanceById(instanceId: string, throwIfNotFound: boolean) {
  const { oDataUrl } = await loadConfig();
  const api = new ODataApi(oDataUrl, "EFormsInstancesView");
  const query: ODataQueryParams = {
    filter: `RecordId eq ${instanceId}`
  };

  const result = await api.runSetQuery<IEFormsInstanceView>({method: "GET"}, query, undefined);
  const instances = result.value.map(i => EFormsInstanceView_Factory.CreateIncoming(i)!);
  const instance = (instances.length && instances[0])  || undefined;
  if (!instance && throwIfNotFound) {
    throw new Error("Can't find form instance with id: " + instanceId);
  }
  return instance;
}


export async function getInstances(statusCodes: EFormsStatusCodes[] = [], pageNum: number, pageSize: number, locationId?: string, sorting?: IColumnSort<IEFormsInstanceView>, patientId?: string) {
  if (statusCodes.length === 0) {
    const response: IPagedApiResponse<IEFormsInstanceView> =
    {
      TotalRecords: 0,
      CurrentPage: pageNum,
      PageSize: pageSize,
      Data: [],
    };

    return response;
  }

  const { oDataUrl } = await loadConfig();
  const api = new ODataApi(oDataUrl, "EFormsInstancesView");

  const statusCodesFilter = statusCodes.map(sc => `LatestStatusCode eq ${sc}`).join(' or ')
  const query: ODataQueryParams = {
    filter: `RecordStatus eq 0 and (${statusCodesFilter})`
      + (patientId ? ` and PatientId eq ${patientId}` : '')
      + (locationId ? ` and (LocationId eq ${locationId} or LocationId eq null)` : ''),
    orderBy: getODataSortExpression(sorting)
  };

  const disablePaging = pageSize < 1;
  const paging: IApiQueryOptions | undefined =
    disablePaging
    ? undefined
    : {
      includeCount: pageNum === 1,
      pageNum: pageNum - 1,
      pageSize
    }

  const result = await api.runSetQuery<IEFormsInstanceView>({method: "GET"}, query, paging);
  const instances = result.value.map(i => EFormsInstanceView_Factory.CreateIncoming(i)!);
  const response: IPagedApiResponse<IEFormsInstanceView> =
    {
      TotalRecords: result.count,
      CurrentPage: pageNum,
      PageSize: pageSize,
      Data: instances,
    };

  return response;
}


export async function getInstanceDetails(instanceId: string, includeStatusHistory = false) {
  const { apiUrl } = await loadConfig();
  //console.info(`Loading template instances.`)
  const url = apiUrl
  .segment(`eforms/instances/${instanceId}`)
  .param({includeStatusHistory})
  .toString();

  const { data } = await http.get<IEFormsInstance>(url);
  const instance = EFormsInstance_Factory.CreateIncoming(data)!;

  return instance;
}


// Note: the POST/instances endpoint only allows submitting new instances,
// or updating status of existing instances.
export async function assignFormToPatient(templateId: string, patientId: string, prefillData: IPrefillData | null, locationId: string) {
  const json = prefillData ? JSON.stringify(prefillData) : null;

  const instance: IEFormsInstance = {
    TemplateId: templateId,
    PatientId: patientId,
    PatientName: '',
    SourceTypeId: EFormsSourceTypes.Office,
    OriginalJSONBody: json as any,
    JSONBody: json as any,
    IsRead: false,
    LocationId: locationId!,
    StatusTrackings: [{StatusCode: EFormsStatusCodes.Assigned, InstanceId: EMPTY_GUID}]
  }

  //console.log(`Assigning form ${templateId} to patient ${patientId}`);
  const { apiUrl } = await loadConfig();
  const url = apiUrl
    .segment(`eforms/instances`)
    .toString();

  const { data } = await http.post<IEFormsInstance>(url, instance);
  //console.log(`Assiged form ${templateId} to patient ${patientId}`);
  return data;
}


// export async function saveNewInstance(instance: IEFormsInstance) {
//   console.assert(instance && !instance.RecordId);
//   const { apiUrl } = await loadConfig();
//   //console.info(`Loading template instances.`)
//   const url = apiUrl.segment(`eforms/instances`)
//   .toString();

//   instance = Object.assign({}, instance);
//   instance.Template = undefined;
//   const { data } = await http.post<IEFormsInstance>(url, instance);
//   //console.log("Instance saved:", data);
//   return data;
// }

// const formatPhoneNumberFields = async () => {
//     const formik = useFormikContext<IPrefillData>();
//     const { template } = await promise;
//     const templateDef = JSON.parse(template.JSONTemplateBody);
//     const phoneFields = Array.from(getTemplateFieldBindings(templateDef)).filter(t => t.type === "phoneNumField");

//     //formik.setValues(values);

//     console.log("Here", formik);
//      for (let phoneField of phoneFields) {
//        const fieldBinding = phoneField.binding;
//        const value = getIn(values, fieldBinding);
//        console.log("fieldBinding " + fieldBinding);
//        console.log("standardizePhoneNumber" + standardizePhoneNumber(value));

//        if (value !== undefined) {
//          console.log("inside of if.");
//          //setIn(fieldBinding, standardizePhoneNumber(value));
//          formik.setFieldValue(fieldBinding, standardizePhoneNumber(value));
//        }
//      };

// }

const formatPhoneNumberFields = async (instance: IEFormsInstance, isPublic?: boolean, tenantInstallationId?: string) => {
  const template = (isPublic && tenantInstallationId) ? await getPublicTemplateDetails(tenantInstallationId, instance.TemplateId)
    : await getPrivateTemplateDetails(instance.TemplateId);
  const templateDef = JSON.parse(template.JSONTemplateBody);
  const phoneFields = Array.from(getTemplateFieldBindings(templateDef)).filter(t => t.type === FormPartType.PhoneNumField);

  for (let phoneField of phoneFields) {
    const fieldBinding = phoneField.binding;
    const value = getIn(instance, fieldBinding);

    if (value !== undefined) { // This needs to check if there's a value in the string at all.
      setIn(instance, fieldBinding, standardizePhoneNumber(value));
    }
  };

}


export async function updateInstance(instance: IEFormsInstance, statusCode?: EFormsStatusCodes) {
  console.assert(instance && instance.RecordId);
  const { apiUrl } = await loadConfig();
  console.info("Updating instance.")
  const url = apiUrl.segment(`eforms/instances`)
    .toString()

  instance = Object.assign({}, instance);
  instance.Template = undefined;

  if (typeof statusCode !== 'undefined') {
    // Replace the StatusTrackings array with only the new status
    const newStatus: IEFormsStatusTracking = {
      InstanceId: instance.RecordId!,
      StatusCode: statusCode
    }
    instance.StatusTrackings = [newStatus];
  }

  formatPhoneNumberFields(instance);
  //console.log(instance);

  const { data  } = await http.post<IEFormsInstanceUpdateResponse>(url, instance);
  //console.log(data);
  return data;
}

export async function updateInstanceIsReadStatus(instanceId: string, isRead: boolean) {
  console.assert(instanceId);
  const { apiUrl } = await loadConfig();
  console.info("Updating instance is read status.")
  const url = apiUrl.segment(`eforms/instances/isReadStatus`)
    .param({instanceId})
    .toString();

  //const { data } = await http.post<IEFormsInstance>(url, isRead);
  const { data } = await http.post<IEFormsInstance>(url, isRead, { headers: { 'Content-Type': 'application/json' } });
  return data;
}


// export async function updateInstanceStatus(instanceId: string, statusCode: EFormsStatusCodes) {
//   const { apiUrl } = await loadConfig();
//   //console.info(`Updating instance status.`)
//   const url = apiUrl.segment(`eforms/instances/${instanceId}`)
//     .param({statusCode})
//     .toString();

//   const { data } = await http.post<IEFormsStatusTracking>(url, {});
//   return EFormsStatusTracking_Factory.CreateIncoming(data)!;
// }



export async function savePublicFormInstance(tenantInstallationId: string, templateId: string, json: string, locationId: string) {
  const instance: IEFormsInstance = {
    PatientName: '',
    TemplateId: templateId,
    SourceTypeId: EFormsSourceTypes.Public,
    OriginalJSONBody: json,
    IsRead: false,
    JSONBody: json,
    LocationId: locationId,
  }

  console.info("Saving form with template Id: " + templateId, JSON.parse(json));
  formatPhoneNumberFields(instance, true, tenantInstallationId);
  const { apiUrl } = await loadConfig();
  const url = apiUrl
    .segment(`eforms/external/instances`)
    .param({tenantInstallationId})
    .toString();

  const { data } = await http.post(url, instance);
  console.log(data);
}
