"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionGroup_Factory = exports.Date_Factory = void 0;
var Date_Factory_1 = require("./Date_Factory");
Object.defineProperty(exports, "Date_Factory", { enumerable: true, get: function () { return Date_Factory_1.Date_Factory; } });
var User_1 = require("./User");
var PermissionGroup_Factory = /** @class */ (function () {
    function PermissionGroup_Factory() {
    }
    PermissionGroup_Factory.CreateIncoming = function (model) {
        if (!model)
            return model;
        var result = {
            AdminPermissionSet: model.AdminPermissionSet,
            ClinicalPermissionSet: model.ClinicalPermissionSet,
            Description: model.Description,
            FinancialPermissionSet: model.FinancialPermissionSet,
            GeneralPermissionSet: model.GeneralPermissionSet,
            InsurancePermissionSet: model.InsurancePermissionSet,
            Name: model.Name,
            PermissionSet1: model.PermissionSet1,
            PermissionSet2: model.PermissionSet2,
            PermissionSet3: model.PermissionSet3,
            RecordCreated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateIncoming(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReportsPermissionSet: model.ReportsPermissionSet,
            SpecialPermissionSet: model.SpecialPermissionSet,
            Users: model.Users && model.Users.map(function (i) { return User_1.User_Factory.CreateIncoming(i); }),
        };
        return result;
    };
    PermissionGroup_Factory.CreateOutgoing = function (model) {
        if (!model)
            return model;
        var result = {
            AdminPermissionSet: model.AdminPermissionSet,
            ClinicalPermissionSet: model.ClinicalPermissionSet,
            Description: model.Description,
            FinancialPermissionSet: model.FinancialPermissionSet,
            GeneralPermissionSet: model.GeneralPermissionSet,
            InsurancePermissionSet: model.InsurancePermissionSet,
            Name: model.Name,
            PermissionSet1: model.PermissionSet1,
            PermissionSet2: model.PermissionSet2,
            PermissionSet3: model.PermissionSet3,
            RecordCreated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordCreated),
            RecordId: model.RecordId,
            RecordLastUpdated: Date_Factory_1.Date_Factory.CreateOutgoing(model.RecordLastUpdated),
            RecordStatus: model.RecordStatus,
            ReportsPermissionSet: model.ReportsPermissionSet,
            SpecialPermissionSet: model.SpecialPermissionSet,
            Users: model.Users && model.Users.map(function (i) { return User_1.User_Factory.CreateOutgoing(i); }),
        };
        return result;
    };
    return PermissionGroup_Factory;
}());
exports.PermissionGroup_Factory = PermissionGroup_Factory;
